// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import $ from "jquery"

// window.jQuery = $
// window.$ = $
// global.jQuery = $
// global.$ = $

const jQuery = require("jquery")
global.$ = window.$ = jQuery
global.jQuery = window.jQuery = jQuery

import "bootstrap"
import "../stylesheets/application"
import "select2/dist/css/select2.min.css"
import select2 from 'select2'

import "summernote"

require("trix")
require("@rails/actiontext")

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

$(function () {
    $('.summernote').summernote({
        height: 500
    })
    $('.data-tables').DataTable({
        "stateSave": true,
    })
    $('.select2-form').select2()
})